
  <template>
  <v-data-table
    :loading="loading" 
    :headers="headers"
    :items="permissions"
    sort-by="calories"
    class="elevation-1"
    dense
    disable-pagination
    disable-sort
    hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Permissões</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
                
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-switch
        dense 
        v-model="item.active"
        hide-details
        @click="save()"
      ></v-switch>
    </template>
    
  </v-data-table>
</template>


<script>
import { mapActions } from 'vuex'
export default {
  data: () => ({
    loading:false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Descrição',
        align: 'start',
        sortable: false,
        value: 'description',
      },

      { text: '', align: 'end', value: 'actions', sortable: false },
    ],
    permissions: [],
  }),

  computed: {},
  created() {
    this.initialize()
  },

  methods: {
    ...mapActions('role', ['getPermission', 'storePermission']),
    initialize() {
      this.loading = true
      this.getPermission(this.$route.params.role_id)
        .then(response => {
          this.permissions = response.data.permissions
          this.permissions_role = response.data.permissions_role
          this.setPermission()
        })
        .finally(() => {
          this.loading = false
        })
    },
    setPermission() {
      const rolePermission = this.rolePermission()
      this.permissions.forEach(item => {
        if (rolePermission.includes(item.id)) {
          item.active = true
        } else {
          item.active = false
        }
      })
    },
    rolePermission() {
      let permission_ids = []
      this.permissions_role.forEach(item => {
        permission_ids.push(item.pivot.permission_id)
      })

      return permission_ids
    },
    save() {
      const data = {
        role_id: this.$route.params.role_id,
        permission: this.permissions,
      }

      this.storePermission(data)
        .then(response => {
          this.$toast.success('Salvo com sucesso.')
        })
        .finally(() => {})
    },
  },
}
</script>

<style>
</style>